import React from 'react'
import { Container } from 'reactstrap'
import CareerRequirements from '../components/CareerRequiremetns/CareerRequirements'
import Layout from '../components/layout/layout'
import { Helmet } from "react-helmet"



const JobDescriptionFullStackUS = () => {
    return (
        <div>
            <Helmet title='DNNae | Deep Learning - Speech JD' />
            <Layout
                containerStyle={{
                    position: 'relative'

                }}
                buttonStatus={false}
                selectedState=''
            >

                <Container
                    
                    style={{
                    maxWidth: '1920px'
                    }}
                >
                <CareerRequirements
                
                    
                    jobTitle='Deep Learning - Speech Engineer'
                    jobTypeAndPlace='Contract, San Francisco'
                    weAreLooking='We are looking for a Deep Learning-Speech Engineer who will partner with both the business and technical teams to innovate and deliver new AI features and enhancements (particularly focused around audio) for our product. You will create and/or modify complex solutions that meet the expectations of users. You will also have a thorough knowledge of the entire system and work with product, engineering and other teams in the organization to deliver the best experience to the users.'
                    responsibilitiesArray={[
                        `Work with the speech, audio software engineering team to deliver a great speech user experience.`,
                        `Have an "On it!" attitude and willingness to work hands-on in building tools, testing, data collection, running experiments and work with state-of-the-art speech and audio processing algorithms.`,
                        `You should thrive in a fast paced environment with constantly evolving priorities, and collaborate well with other engineering teams at this company.`,
                    ]}
                    
                    whatYouNeedArray={[
                        `2+ years experience applying deep learning technologies to computer vision, speech recognition, natural language understanding, etc.`,
                        `Proficiency in programming languages including but not limited to Python/C/C++`,
                        `Extensive experience with machine learning frameworks like Tensorflow, PyTorch`,
                        `Strong understanding of embedded systems and server-side engineering optimization`,
                        `Outstanding written and verbal communication, with the ability to work well in multi-functional teams`,
                        `Experience on speech recognition, and TinyML is preferred`,
                    ]}
                >

                </CareerRequirements>

                </Container>
            </Layout>
        </div>
    )
}

export default JobDescriptionFullStackUS
